import React, {useEffect} from 'react';
import './Production.css';
import RetailImg1 from '../components/Asset/shopiretails.jpeg'
import RetailImg2 from '../components/Asset/Angazaretail_uifcad.jpg'
import RetailImg3 from '../components/Asset/techretails.jpeg'
import ContactCard from './ContactCard';
import AOS from 'aos';
import 'aos/dist/aos.css';

const retailInfo = [
  {
    src : RetailImg1 , alt: "Shoping image" , captions : "Angaza Retail: We help Retail businesses optimize their operations through offering comprehensive third party retail auditing services. This is aimed to improve the overall performance of the business, strengthening customer service, operations,staff performance and inventory management."
  },
  {
    src : RetailImg2 , alt: "Beauty image" , captions : "Angaza Beauty: Elevating African Brands with End-to-End Solutions – From Ethical Product Development and Lab Services to Strategic Brand Management and Streamlined Supply Chain. We empower African beauty brands to compete globally, delivering the quality, innovation, and support they need to thrive in the beauty market."
  },
  {
    src : RetailImg3 , alt: "Tech images" , captions : "Angaza Tech: Transforming Retail with Innovative Solutions – From Custom KPI Trackers and Inventory Management Tools to Seamless SaaS Integration. We empower African retail businesses to optimize operations, boost efficiency, and make data-driven decisions that drive growth"
  },
]

const ProductionCard = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initializes AOS with a duration of 1000ms
  }, []);

  return (
  <div className='Retail-container' data-aos = 'fade-up'>
    <div className='Production-heading'>
      <h2>ANGAZA PLACE LIMITED.</h2>
      <h4>Ethically driven, innovative and efficiency</h4>
    </div>
    <div className='production-cards'>
      {retailInfo.map((item, Index) => (
        <div key={Index} className='production-card' data-aos = 'fade-up'>
          <img src={item.src} alt={item.alt} className='prodution-img' />
          <p className='production-captions'>{item.captions}</p>
          </div>
      ))}
    </div>
    </div>
  )
}

export default ProductionCard;
