import React from 'react';
import "./Footer.css";
import { FaFacebook, FaInstagram, FaYoutube,  FaLinkedin } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6"; // Import icons
import LogoFooterimg from '../components/Asset/logo.png';
import { Link } from "react-router-dom";

const companyLinks = [
  { heading: "Blog", link: "/blog" },
  { heading: "Faq's", link: "/faqs" },
  { heading: "Privacy policies", link: "/privacy-policies" },
  { heading: "Contact", link: "/contact" },
];

const quickLinks = [
  { heading: "About" },
  { heading: "Community" },
  { heading: "Products" },
  { heading: "Join us" },
];

const socialMediaLinks = [

  { name: "Instagram", icon: <FaInstagram />, url: "https://www.instagram.com/angazaplace/profilecard/?igsh=eDZpdHdyY20wYml5" },
  { name: " Linkedin", icon: < FaLinkedin/>, url: "http://www.linkedin.com/in/angaza-place-00199b320" },
];

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year copywrite

  return (
    <div className='footer-main-container'>
      <div className='footer-container'>
        <div className='logo-footer'>
          <img src={LogoFooterimg} alt='logo' />
        </div>

        <div className='footer-links'>
            <h2>Company</h2>
            {companyLinks.map((link, index) => (
         <h4 key={index}>
            <Link to={link.link} className="footer-link">
            {link.heading}
            </Link>
         </h4>
              ))}
         </div>


        <div className='footer-links'>
          <h2>Quick Links</h2>
          {quickLinks.map((link, index) => (
            <h4 key={index}>{link.heading}</h4>
          ))}
        </div>

        <div className='footer-links'>
          <h2>Follow Us</h2>
          <div className='social-media-icons'>
            {socialMediaLinks.map((social, index) => (
              <a
                key={index}
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                className='social-icon'
                aria-label={social.name}
              >
                {social.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className='footer-copywrite'>
        <p>© {currentYear} Angaza Place Limited. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
