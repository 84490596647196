import React, {useEffect} from 'react';
import './Contact.css';
import { FaLocationDot } from "react-icons/fa6";
import { MdAddCall } from "react-icons/md";
import { IoMdMailUnread } from "react-icons/io";
import Swal from 'sweetalert2';
// import GoggleMapCard from './GoggleMapCard';
import Footer from './Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactCard = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    // ebccf34c-ba2d-47eb-bcd4-d0334895eee9
    formData.append("access_key", "578114e1-f621-4760-a5c4-a1daf01ba0af");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: "Success",
        text: "Form submitted successfully!",
        icon: "success",
        confirmButtonText: "OK"
      });
    } else {
      Swal.fire({
        title: "Error",
        text: "Failed to submit the form. Please try again later.",
        icon: "error",
        confirmButtonText: "OK"
      });
    }
  };

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initializes AOS with a duration of 1000ms
  }, []);

  return (
  
    <>
      <div className='Contact-container' >
        <div className='contact-heading' data-aos = 'fade-up'>
          <h2>Contact Us.</h2>
          <p>Whether you're a retail businesses looking to optimize retail operations, beauty brands looking enhance your supply chain, or retail business looking for a software to assist you run your operations , Angaza Place Ltd is here to help. Reach out to learn more about how our tailored solutions in brand management, tech-driven SaaS tools, and R&D support can empower your business.</p>
        </div>
        <div className='contact-card'>
          <div className='contact-information'>

            {/* Call, mail, and location grid */}
            <div className='contact-info'>
              <h2><MdAddCall /></h2>
              <div className='contact_head-info'>
                <h4><b>Phone Number:</b> <br/><i>+254 758 307 530</i></h4>
              </div>
            </div>
            <div className='contact-info'>
              <h2><IoMdMailUnread /></h2>
              <div className='contact_head-info'>
                <h4><b>Email:</b> <br/><i>info@angazaplace.co.ke</i></h4>
              </div>
            </div>
            <div className='contact-info'>
              <h2><FaLocationDot /></h2>
              <div className='contact_head-info'>
                <h4><b>Address:</b> <br/><i>Westlands, Nairobi</i></h4>
              </div>
            </div>
          </div>

          {/* Form container */}
          <div className='form-container' data-aos = 'fade-up'>
            <h3 style={{ fontSize: '1.5rem' }}>Contact us.</h3>
            <p>Our team is ready to answer your questions and explore how we can support your growth.</p>
            <br/>

            <form onSubmit={onSubmit}>
              <div className='inputs-form'>
                <div className='input-name-container'>
                  <div className='input-name'>
                    <input type='text' name="firstName" placeholder='First Name' required />
                  </div>
                  <div>
                    <input type='text' name="lastName" placeholder='Last Name' required />
                  </div>
                </div>

                {/* Email and phone input */}
                <div className='information-inputs'>
                  <div className='text-inputs'>
                    <input type='email' name="email" placeholder='Email' required />
                  </div>
                  <div className='text-inputs'>
                    <input type='text' name="phoneNumber" placeholder='Phone Number' />
                  </div>
                  <div className='text-inputs'>
                    <textarea type='text' name="text" placeholder='Message us' />
                  </div>
                </div>

                {/* Submit button */}
                <div className='contact-info-btn'>
                  <button type='submit'>Send</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactCard;
