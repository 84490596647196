import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Blog from './Blog';

const Main = () => {
  return (
    <Routes>
      {/* Define Main-specific routes */}
      <Route path="/blog" element={<Blog />} />
    </Routes>
  );
};

export default Main;
