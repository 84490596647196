import React, { useEffect } from 'react';
import aboutimg from "../components/Asset/Free_Photo___African_american_business_woman_working_in_a_cafe_f9pjh0.jpg";
import "./Home.css";
import ProductionCard from './ProductionCard';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutCard = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initializes AOS with a duration of 1000ms
  }, []);

  return (
    <div className='About-section'>
      <div className='About-container'>
        <img
          src={aboutimg}
          alt="About us"
          data-aos="fade-right" // Animation applied to the image
        />
        <div className='About-headings' data-aos="fade-up"> {/* Animation for text */}
          <h2>About us</h2>
          <p>Angaza Place Ltd is dedicated to driving success for African beauty and retail businesses through expert-led solutions
             in retail operations optimization, beauty brand management, with tech-driven innovation. We offer comprehensive third-party
            retail audits, strategic brand management, and robust supply chain services tailored to empower beauty brands and retail businesses.
            Our SaaS tools help streamline operations, optimize inventory management, and enhance customer analytics for improved decision-making.
           With a commitment to sustainable growth, we also support R&D efforts, enabling brands to develop ethical, high-impact products that resonate with today's market.
          </p>
        </div>
      </div>
      <ProductionCard data-aos="fade-up" /> {/* Animation for ProductionCard */}
    </div>
  );
}

export default AboutCard;
