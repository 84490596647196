import React, { useState } from 'react';
import './Navbar.css';
import navlogo from "../components/Asset/logo.png"
import { Link } from 'react-router-dom';
import { HiMiniBars3BottomRight } from "react-icons/hi2";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const handleScrollToContact = () => {
    const contactSection = document.getElementById('contact-section');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='nav-container'>
      {/* Navbar logo */}
      <div className='nav-logo'>
        <Link to='/'>
          <img src={navlogo} alt='logo' />
        </Link>
      </div>

      {/* Menu icon for Mobile phones */}
      <div className='menu-icon' onClick={toggleMenu}>
        <HiMiniBars3BottomRight size={30} />
      </div>

      {/* Navbar links */}
      <div className={`nav-list ${isMenuOpen ? 'open' : ''}`}>
        <div className='center-links'>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
        {/* Contact link separated */}
        <div className='contact-links'>
          <ul>
            <li><a onClick={handleScrollToContact}>Contact us</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
