import './App.css';
import Main from './components/companyLinks/Main';
import Home from './components/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
      {/* display video background only on homepage */}
      <div className='header-app'>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/*' element = {<Main />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;