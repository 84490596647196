import React from 'react';
import BackGroundVideo from './BackGroundVideo';
import "./Home.css";
import { useNavigate } from 'react-router-dom';
import AboutCard from './AboutCard';
import ContactCard from './ContactCard';

const Home = () => {
  const navigate = useNavigate();

  const handleBtn = () => {
    navigate("/login")
  }

  const handleScrollToContact = () => {
    const contactSection = document.getElementById('contact-section');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
    <div>
      <BackGroundVideo />
      <div className='Home-container'>
        <h2>ANGAZA PLACE LIMITED</h2>

        {/* our background btn */}
        <div className='home-btn' onClick={handleScrollToContact}>
          <button type='button'> CONTACT US</button>
        </div>
      </div>
      <AboutCard />
      <div id="contact-section">
        <ContactCard />
      </div>
    </div>
    </>
  )
}

export default Home;