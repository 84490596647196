import React, {useEffect} from 'react';
import "./Main.css";
import blogimage from "../Asset/WhatsApp Image 2024-11-18 at 22.23.33.jpeg";
import Footer from '../Footer';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../Navbar';

const blogLinks = [

  { name: "Instagram", icon: <FaInstagram />, url: "https://www.instagram.com/angazaplace/profilecard/?igsh=eDZpdHdyY20wYml5" },
  { name: " Linkedin", icon: < FaLinkedin/>, url: "http://www.linkedin.com/in/angaza-place-00199b320" },
];

const Blog = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initializes AOS with a duration of 1000ms
  }, []);
  
  return (
    <div className='blog-container' >
      <Navbar />
      <div className='blog-image' >
      <h1><span className='blog-name'>M</span><span className='blog-last-name' >aureen Onyango </span> </h1>
     <img src={blogimage} alt='Ceo of Angza image' data-aos = "fade-left" />
     </div>
     <div className='blog-heading' data-aos = "fade-right">
      <h2><span className="big-b">B</span><span className="rest-of-word">LOG</span></h2>
     </div>
     <div className='blog-paragraph'  data-aos = "fade-right">
      <h3>Maureen Onyango - Founder & CEO</h3>
      <p>
        Maureen Onyango is a visionary leader and the driving force behind Angaza Place Limited. With over a decade of experience spanning beauty and retail Maureen combines her deep industry knowledge with a passion for empowering businesses to achieve sustainable growth. Her expertise includes brand management, retail operations, sales, and marketing, as well as research and development for ethical and high-performing beauty products.
      </p>

      <p>
        As a dynamic entrepreneur, Maureen is committed to fostering innovation and social impact. Her leadership has shaped Angaza Place Limited into a hub for transformative solutions, from streamlining supply chains to integrating technology for operational efficiency.
      </p>

      <p>
       Maureen has worked with notable organizations, including Lintons Beauty World. A mentor and advocate for women in leadership, she envisions Angaza Place as a catalyst for change in Africa’s beauty and retail landscape.
      </p>
      <h3>
      {blogLinks.map((social, index) => (
              <a 
                key={index}
                href={social.url}
                target="_blank"
                rel="noopener noreferrer"
                className='social-icon'
                aria-label={social.name}
              >
                {social.icon}
                
              </a>

            ))}
      </h3>
     </div>
     <Footer />
    </div>
  )
}

export default Blog
