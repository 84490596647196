import React from 'react';
import "./Home.css"
import Navbar from './Navbar';

const BackGroundVideo = () => {
  return (
    <div className='video-background-wrapper'>
      {/* video source   */}
      <Navbar />
      <video className="background-video" autoPlay loop muted>
        <source src="https://res.cloudinary.com/djyfoquip/video/upload/v1730808144/AngaZa_puhboi.mp4" type="video/mp4" />
      </video>
      
    </div>
  )
}

export default BackGroundVideo;
